class RemoveFields {
  constructor() {
    this.iterateLinks()
  }

  iterateLinks() {
    document.addEventListener('click', e => {
      if (e.target && e.target.className.includes('remove_fields')) {
        this.handleClick(e.target, e)
      }
    })
  }

  handleClick(link, e) {
    if (!link || !e) return

    e.preventDefault()
    let fieldParent = link.closest('.menu-item')
    let deleteField = fieldParent
      ? fieldParent.querySelector('input[type="hidden"]')
      : null

    if (deleteField) {
      deleteField.value = 1
      fieldParent.style.display = 'none'
    }
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new RemoveFields())
