import Rails from '@rails/ujs';

$(document).on('change', '.page-accommodation-select', function(event) {
  let pageId = $('.page-id')[0].value || 0
  Rails.ajax({
    url: '/admin/pages/' + pageId + '/change_sidebar/' + event.target.value,
    type: "get",
    success: function(data) { Rails.$(".page-edit-sidebar")[0].innerHTML = data.html; }
  })
});

$(document).on("drag", () => {
  $(".sortable").sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize')
      });
    }
  });
});

$(document).on('click', '.page-remove-image', function(event) {
  let imageId = event.target.id
  let pageId = $('.page-id')[0].value || 0

  const confirmation = confirm('Are you sure?')

  if (confirmation) {
    Rails.ajax({
      url: '/admin/pages/' + pageId + '/detach_or_delete_image/' + imageId,
      type: "get",
      success: function() {
        Rails.$(`#attachment_${imageId}`)[0].remove()
      }
    })
  }
});
