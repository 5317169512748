import "bootstrap/scss/bootstrap"
import "./shared"
import "./myscript"
import "../stylesheets/public"
import "./chat"

// probably not best practice to include admin in public, however this works for now
import './admin/accommodation_image_attachment'
import './nested-forms/addFields'
import './nested-forms/removeFields'
import './admin/page'
import './admin/site'
import './admin/copy_accommodation_attribute'
import './admin/bed_type_selection'
import "./admin/availability"
