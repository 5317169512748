$(document).on('click', '.attachable', function(event) {
  let target = event.target;
  let value = target.previousSibling.textContent;

  if (value != '\n\n') {
    let attribute = target.previousElementSibling.textContent.toLowerCase().split(' ').join('_');
    var element = document.getElementById('page_' + attribute);
    let number = parseInt(value, 10)
    if (number >= 0) {
      element.value = number
    } else {
      element.value = value
    };
  }
});
