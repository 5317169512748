import Rails from '@rails/ujs';

$(document).on('click', '.add-page-to-menu', function(event) {
  event.preventDefault()

  let siteId = $('.site-id')[0].value || 0
  let pageId = $('.site-page-add-select')[0].value

  Rails.ajax({
    url: '/admin/sites/' + siteId + '/' + pageId + '/add_page_to_menu_items',
    type: "get",
    success: function(data) { Rails.$(".menu-items")[0].innerHTML += data.html; }
  })
});
