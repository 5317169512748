// This module is used to add accommodation images to page content or page model
// depending on switched value.
// In case of addition to page content:
// Trix doesn't allow adding images in non-upload way by default, here we are adding such a option.

import Rails from '@rails/ujs';
import Trix from 'trix'

// $(document).on('click', '.page-accommodation-image-attachable', function(event) {
//   if ($('#accommodation-image-switch')[0].checked) {
//     attachToPageModel(event)
//   } else {
//     attachImageToPageContent(event)
//   }
// });

function attachImageToPageContent(event) {
  var element = document.querySelector("trix-editor")

  if (!element) {
    alert('There is no rich content editor on this page!')
    return
  }

  const target = event.target
  const url = target.src
  const contentType = target.attributes.content_type.nodeValue
  const width = target.naturalWidth
  const height = target.naturalHeight
  const attachment = new Trix.Attachment({ contentType, url, width, height });
  element.editor.insertAttachment(attachment);
}

function attachToPageModel(event) {
  console.log('kakakak')
  let imageId = event.target.id
  if (window.location.href.substr(-3) == "new") {
    alert('Save the page before adding images')
  } else {
    var pageId = $('.page-id')[0].value || 0
  }

  if ($(`.w-25#${imageId}`)[0]) { return null }

  Rails.ajax({
    url: '/admin/pages/' + pageId + '/attach_accommodation_image/' + imageId,
    type: "get",
    success: function(data) {
      Rails.$(".sortable")[0].innerHTML += data.html;
    }
  })
}
