import flatpickr from "flatpickr"
import { Dutch } from "flatpickr/dist/l10n/nl.js"

document.addEventListener("turbo:load", () => {
	connectMenu();
	connectFlatpickr();
	connectAccommodationFilters();
	connectSwiper();
});

function connectMenu() {
	const iconMenu = document.querySelector('.menu__icon');
	const menuBody = document.querySelector('.menu__body');
	if (iconMenu) {
		iconMenu.addEventListener("click", function (e) {
			document.body.classList.toggle('_lock');
			iconMenu.classList.toggle('_active');
			menuBody.classList.toggle('_active');
		});
	}
}

function connectAccommodationFilters() {
	const toggleFilters = document.getElementById('toggleFilters');
	const containerFilters = document.getElementById('containerFilters');
	if (toggleFilters) {
		toggleFilters.addEventListener('click', function (e) {
			if (containerFilters.style.display === "none") {
					containerFilters.style.display = "block";
				} else {
					containerFilters.style.display = "none";
				}
		})
	}
}

function connectSwiper() {
	new Swiper(".mySwiper", {
		freeMode: true,
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			"760": {
				slidesPerView: "3",
				spaceBetween: 0,
				pagination: {
						el: "",
				},
			},
			"280": {
				slidesPerView: 1,
				pagination: {
						el: ".swiper-pagination",
						type: "fraction",
				},
			},
		},
	});
}

function connectFlatpickr() {
	var startAtInput = document.getElementById('start_at');
	var endAtInput = document.getElementById('end_at');
	if (startAtInput && endAtInput) {
		startAtInput.flatpickr({
			dateFormat: "d-m-Y",
			minDate: "today",
			"locale": Dutch,
			onChange: function(dateStr) {
				end_at.set('minDate', dateStr)
			}
		});
		var end_at = endAtInput.flatpickr({
			dateFormat: "d-m-Y",
			minDate: "today",
			"locale": Dutch,
		});
	}
};
