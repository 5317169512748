$(document).on('change', '.bed-type-selection', function(event) {
  let target = event.target
  let value = $(this).val()
  if (value == 'double' || value == 'staple') {
    target.parentNode.nextSibling.nextSibling.children[1].value = '2'
  } else {
    target.parentNode.nextSibling.nextSibling.children[1].value = '1'
  }
});  


