import flatpickr from "flatpickr"
import { Dutch } from "flatpickr/dist/l10n/nl.js"

document.addEventListener("turbo:load", () => {
	var dateRange = document.getElementById('date_range');
	if (dateRange != null) {
		connectFlatpickrRangeAdmin();
	}
});

function connectFlatpickrRangeAdmin() {
  var dateRange = document.getElementById('date_range');

	if (dateRange) {
		dateRange.flatpickr({
			mode:	"range",
			dateFormat: "d-m-Y",
			minDate: "today",
			"locale": Dutch,
		});
	}
};
